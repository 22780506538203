/* line 6, stdin */
.sideMenu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
}

/* line 126, node_modules/@labor/bootstrap-addons/sass/mixins/generalHelpers.sass */
.sideMenu li {
  display: block;
  padding: 0;
  margin: 0;
}

/* line 12, stdin */
.sideMenu__link {
  display: block;
  padding: 10px;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  color: #777777;
  border-left: 5px solid transparent;
}

/* line 20, stdin */
.sideMenu__link:not(.sideMenu__link--active):hover {
  border-left-color: #fbba00;
}

/* line 23, stdin */
.sideMenu__link--active {
  border-left-color: #004f9f;
}
