@charset "UTF-8";
/* line 9, stdin */
.login__headline {
  display: block;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 45px;
  line-height: 55px;
  color: #004f9f;
  margin-bottom: 30px;
}

/* line 12, stdin */
.login__desc {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
}

/* line 3, node_modules/@labor/bootstrap-addons/sass/mixins/links.sass */
.login__button, .login__button:link, .login__button:hover, .login__button:active, .login__button:visited {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  font-family: "Interstate", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: bold;
  display: block;
  margin: 0;
  text-align: left;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  padding: 9px 40px 10px 18px;
  border: 2px solid #004f9f;
  background-color: #004f9f;
  color: #fff;
}

/* line 45, webroot/frontend-legacy/src/Mixins/Buttons.sass */
.login__button:after, .login__button:link:after, .login__button:hover:after, .login__button:active:after, .login__button:visited:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 14px;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -5px;
}

/* line 58, webroot/frontend-legacy/src/Mixins/Buttons.sass */
.login__button:after, .login__button:before, .login__button:link:after, .login__button:link:before, .login__button:hover:after, .login__button:hover:before, .login__button:active:after, .login__button:active:before, .login__button:visited:after, .login__button:visited:before {
  color: #fff;
}
