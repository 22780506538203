/* line 6, stdin */
.sqlDump {
  border-top: 1px solid #adadad;
  padding-top: 15px;
}

/* line 11, stdin */
.sqlDump table {
  width: 100%;
}

/* line 14, stdin */
.sqlDump td, .sqlDump th, .sqlDump caption {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #777777;
  font-family: "Interstate", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  color: #777777;
}

/* line 19, stdin */
.sqlDump td, .sqlDump th {
  padding: 15px;
}

/* line 21, stdin */
.sqlDump td:not(:last-child), .sqlDump th:not(:last-child) {
  border-right: 1px solid #adadad;
}
