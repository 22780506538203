/* line 9, stdin */
.datepicker-canvas {
  margin-left: 20%;
  padding-left: 30px;
}

/* line 13, stdin */
.datepicker-canvas-external {
  margin-left: 0;
  padding-left: 0;
}

/* line 17, stdin */
.datepicker-canvas-external .datepicker-container {
  width: 100%;
}

/* line 20, stdin */
.datepicker-container {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  width: 52%;
  margin-top: 30px;
}

/* line 25, stdin */
.datepicker-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
}

/* line 126, node_modules/@labor/bootstrap-addons/sass/mixins/generalHelpers.sass */
.datepicker-container ul li {
  display: block;
  padding: 0;
  margin: 0;
}

/* line 28, stdin */
.datepicker-container .datepicker-hide {
  display: none;
}

/* line 31, stdin */
.datepicker-container ul[data-view="months"],
.datepicker-container ul[data-view="years"],
.datepicker-container ul[data-view="week"],
.datepicker-container ul[data-view="days"] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
}

/* line 40, stdin */
.datepicker-container ul[data-view="months"] li,
.datepicker-container ul[data-view="years"] li,
.datepicker-container ul[data-view="week"] li,
.datepicker-container ul[data-view="days"] li {
  padding: 5px;
  text-align: center;
  border-right: 1px solid #adadad;
}

/* line 45, stdin */
.datepicker-container ul[data-view="months"] li:hover:not(.picked):not(.disabled),
.datepicker-container ul[data-view="years"] li:hover:not(.picked):not(.disabled),
.datepicker-container ul[data-view="week"] li:hover:not(.picked):not(.disabled),
.datepicker-container ul[data-view="days"] li:hover:not(.picked):not(.disabled) {
  background-color: fade(#004f9f, 20%);
}

@media (min-width: 0px) and (max-width: 449px) {
  /* line 40, stdin */
  .datepicker-container ul[data-view="months"] li,
  .datepicker-container ul[data-view="years"] li,
  .datepicker-container ul[data-view="week"] li,
  .datepicker-container ul[data-view="days"] li {
    padding: 0;
    padding-bottom: 5px;
    padding-top: 5px;
  }
}

/* line 55, stdin */
.datepicker-container ul[data-view="months"] li,
.datepicker-container ul[data-view="years"] li {
  width: 16.66667%;
}

/* line 57, stdin */
.datepicker-container ul[data-view="months"] li:nth-child(6n),
.datepicker-container ul[data-view="years"] li:nth-child(6n) {
  border-right: none;
}

/* line 62, stdin */
.datepicker-container ul[data-view="week"] li,
.datepicker-container ul[data-view="days"] li {
  width: 14.28571%;
}

/* line 64, stdin */
.datepicker-container ul[data-view="week"] li:nth-child(7n),
.datepicker-container ul[data-view="days"] li:nth-child(7n) {
  border-right: none;
}

/* line 67, stdin */
.datepicker-container ul[data-view="week"] li {
  color: #004f9f;
  font-family: "Interstate", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  cursor: default;
}

/* line 73, stdin */
.datepicker-container ul[data-view="week"] li:hover:not(.picked):not(.disabled) {
  background-color: transparent;
}

/* line 76, stdin */
.datepicker-container .disabled,
.datepicker-container .muted {
  background-color: #f5f5f5;
  color: #777777;
}

/* line 81, stdin */
.datepicker-container .disabled {
  background-color: #ebebeb;
  cursor: default;
}

/* line 85, stdin */
.datepicker-container .picked {
  background-color: #004f9f;
  color: #fff;
}

/* line 89, stdin */
.datepicker-container .datepicker-panel > ul:first-child {
  display: flex;
  flex: 1 auto;
  height: 45px;
  margin-bottom: 15px;
}

@media (min-width: 0px) and (max-width: 449px) {
  /* line 89, stdin */
  .datepicker-container .datepicker-panel > ul:first-child {
    height: 55px;
  }
}

/* line 98, stdin */
.datepicker-container .datepicker-panel > ul:first-child li[data-view*="prev"],
.datepicker-container .datepicker-panel > ul:first-child li[data-view*="next"],
.datepicker-container .datepicker-panel > ul:first-child li[data-view*="current"] {
  text-align: center;
  display: block;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  color: #004f9f;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* line 106, stdin */
.datepicker-container .datepicker-panel > ul:first-child li[data-view*="prev"].disabled,
.datepicker-container .datepicker-panel > ul:first-child li[data-view*="next"].disabled,
.datepicker-container .datepicker-panel > ul:first-child li[data-view*="current"].disabled {
  color: #777777;
  background-color: transparent;
}

/* line 110, stdin */
.datepicker-container .datepicker-panel > ul:first-child li[data-view*="prev"]:hover:not(.picked):not(.disabled),
.datepicker-container .datepicker-panel > ul:first-child li[data-view*="next"]:hover:not(.picked):not(.disabled),
.datepicker-container .datepicker-panel > ul:first-child li[data-view*="current"]:hover:not(.picked):not(.disabled) {
  background-color: fade(#004f9f, 20%);
  cursor: pointer;
}

/* line 114, stdin */
.datepicker-container .datepicker-panel > ul:first-child li[data-view*="prev"],
.datepicker-container .datepicker-panel > ul:first-child li[data-view*="next"] {
  width: 15%;
  min-width: 30px;
}

/* line 119, stdin */
.datepicker-container .datepicker-panel > ul:first-child li[data-view*="current"] {
  flex: 2 auto;
}
