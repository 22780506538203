@charset "UTF-8";
/* line 6, stdin */
.flash {
  margin-top: 30px;
  margin-bottom: 60px;
  padding: 15px;
  background-color: #004f9f;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  color: #fff;
}

/* line 14, stdin */
.flash:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 15px;
  color: #fff;
}

/* line 18, stdin */
.flash--error {
  background-color: firebrick;
}

/* line 20, stdin */
.flash--error:before {
  content: "";
}
