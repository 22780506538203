@charset "UTF-8";
/* line 6, stdin */
.breadcrumbs {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
}

/* line 126, node_modules/@labor/bootstrap-addons/sass/mixins/generalHelpers.sass */
.breadcrumbs li {
  display: block;
  padding: 0;
  margin: 0;
}

/* line 10, stdin */
.breadcrumbs__link {
  display: inline-block;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  color: #004f9f;
}

/* line 16, stdin */
.breadcrumbs__link:after {
  display: inline-block;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 11px;
  color: #004f9f;
  padding-right: 5px;
  padding-left: 10px;
}

/* line 23, stdin */
.breadcrumbs li.breadcrumbs__child {
  display: inline-block;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  color: #777777;
}

/* line 29, stdin */
.breadcrumbs li.breadcrumbs__child:last-child .breadcrumbs__link:after {
  content: "";
  cursor: default;
}
