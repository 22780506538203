/* line 6, stdin */
.pagination {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  width: 50%;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  text-align: center;
  display: block;
  margin: 0 auto;
  margin-top: 15px;
}

/* line 126, node_modules/@labor/bootstrap-addons/sass/mixins/generalHelpers.sass */
.pagination li {
  display: block;
  padding: 0;
  margin: 0;
}

/* line 17, stdin */
.pagination li {
  display: inline-block;
  height: 30px;
  min-width: 30px;
}

/* line 27, stdin */
.pagination__first a, .pagination__last a, .pagination__next a, .pagination__child a, .pagination__prev a {
  color: #004f9f;
}

/* line 30, stdin */
.pagination__active {
  font-family: "Interstate", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
}
