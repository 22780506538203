/* line 9, stdin */
.leadHistory__row {
  cursor: pointer;
}

/* line 12, stdin */
.leadHistory__time {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #777777;
}

/* line 15, stdin */
.leadHistory__path {
  display: inline-block;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  color: #004f9f;
  font-family: "Interstate", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
}

/* line 21, stdin */
.leadHistory__query {
  display: inline-block;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  color: #777777;
}

/* line 26, stdin */
.leadHistory__blockContent {
  float: left;
  width: 100%;
  background-color: #fdfdfd;
}

/* line 31, stdin */
.leadHistory .recordDetail__listChild {
  float: left;
  -webkit-box-shadow: inset 0px 0px 27px -5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px 0px 27px -5px rgba(0, 0, 0, 0.25);
  -o-box-shadow: inset 0px 0px 27px -5px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px 0px 27px -5px rgba(0, 0, 0, 0.25);
}
