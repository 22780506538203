/* line 6, stdin */
.autocomplete-suggestions {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  background-color: #fff;
  border-top: 1px solid #adadad;
  border-right: 1px solid #adadad;
  border-bottom: 1px solid #adadad;
  border-left: 1px solid #adadad;
  border-top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* line 14, stdin */
.autocomplete-suggestions .autocomplete-suggestion {
  width: 100%;
  border-left: 5px solid transparent;
  border-bottom: 0;
  padding: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
}

/* line 23, stdin */
.autocomplete-suggestions .autocomplete-suggestion.autocomplete-selected {
  border-left: 5px solid #fbba00;
  border-bottom: 0;
}
