/* line 4, webroot/frontend-legacy/src/Components/Dashboard/DashboardBox.sass */
.dashboardBox__headline {
  display: block;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  color: #004f9f;
  margin-bottom: 0;
  color: #777777;
  margin-bottom: 15px;
}

/* line 9, webroot/frontend-legacy/src/Components/Dashboard/DashboardBox.sass */
.dashboardBox__content {
  border-top: 1px solid #adadad;
  border-left: 1px solid #adadad;
  border-right: 1px solid #adadad;
  padding: 20px;
}

/* line 15, webroot/frontend-legacy/src/Components/Dashboard/DashboardBox.sass */
.dashboardBox__footer {
  border-top: 1px solid #adadad;
  border-right: 1px solid #adadad;
  border-bottom: 1px solid #adadad;
  border-left: 1px solid #adadad;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f5f5f5;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  text-align: right;
}

/* line 25, webroot/frontend-legacy/src/Components/Dashboard/DashboardBox.sass */
.dashboardBox__footer a {
  color: #004f9f;
}

/* line 28, webroot/frontend-legacy/src/Components/Dashboard/DashboardBox.sass */
.dashboardBox__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
}

/* line 126, node_modules/@labor/bootstrap-addons/sass/mixins/generalHelpers.sass */
.dashboardBox__list li {
  display: block;
  padding: 0;
  margin: 0;
}

/* line 31, webroot/frontend-legacy/src/Components/Dashboard/DashboardBox.sass */
.dashboardBox__list > li {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
}

/* line 34, webroot/frontend-legacy/src/Components/Dashboard/DashboardBox.sass */
.dashboardBox__list .dashboardBox__listRow {
  display: flex;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  color: #333333;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

/* line 41, webroot/frontend-legacy/src/Components/Dashboard/DashboardBox.sass */
.dashboardBox__list .dashboardBox__listRow:not(:last-child) {
  border-bottom: 1px solid #adadad;
}

/* line 45, webroot/frontend-legacy/src/Components/Dashboard/DashboardBox.sass */
.dashboardBox__listLeadTitle {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #777777;
  display: block;
  margin-top: -5px;
}

/* line 50, webroot/frontend-legacy/src/Components/Dashboard/DashboardBox.sass */
.dashboardBox__listLead a, .dashboardBox__listLink {
  color: #004f9f;
  font-family: "Interstate", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
}

/* line 55, webroot/frontend-legacy/src/Components/Dashboard/DashboardBox.sass */
.dashboardBox__listDate {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #777777;
  line-height: 25px;
}
