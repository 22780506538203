/* line 26, stdin */
.page__headline {
  display: block;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 45px;
  line-height: 55px;
  color: #004f9f;
  margin-bottom: 30px;
  margin-top: 30px;
}
