@charset "UTF-8";
/* line 10, stdin */
.organigram__button:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 17px;
  color: #004f9f;
  margin-right: 30px;
  cursor: pointer;
}

/* line 17, stdin */
.organigram__button.expanded:after {
  content: "";
}

/* line 20, stdin */
.organigram__buttonSpacer {
  display: inline-block;
  width: 43px;
  height: 20px;
}

/* line 25, stdin */
.organigram__id {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  color: #777777;
}

/* line 30, stdin */
.organigram__id--legacy {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  color: #777777;
  font-family: "Interstate", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
}

/* line 35, stdin */
.organigram__region, .organigram__branch, .organigram__employee {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  color: #333333;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid #adadad;
}

/* line 42, stdin */
.organigram__branch {
  padding-left: 30px;
}

/* line 45, stdin */
.organigram__employee {
  padding-left: 120px;
}

/* line 48, stdin */
.organigram__employeeDetails {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  color: #777777;
}
