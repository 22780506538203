@charset "UTF-8";
/* line 6, stdin */
.mainMenu {
  position: relative;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  text-align: right;
}

/* line 13, stdin */
.mainMenu__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  display: inline-block;
  width: 100%;
}

/* line 126, node_modules/@labor/bootstrap-addons/sass/mixins/generalHelpers.sass */
.mainMenu__list li {
  display: block;
  padding: 0;
  margin: 0;
}

/* line 18, stdin */
.mainMenu__list li.mainMenu__listItem {
  display: inline-block;
  position: relative;
}

/* line 24, stdin */
.mainMenu__list li.mainMenu__listItem--dropdown:hover > .mainMenu__dropdown {
  margin-top: 0;
}

/* line 28, stdin */
.mainMenu__list li.mainMenu__listItem--dropdown > .mainMenu__link:after {
  float: right;
  clear: both;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 10px;
  color: #004f9f;
  display: inline-block;
  margin: 7px 5px 0 5px;
  border: 0;
}

/* line 38, stdin */
.mainMenu__list li.mainMenu__listItem--dropdown.open > .mainMenu__link:after {
  content: "";
}

/* line 41, stdin */
.mainMenu__link {
  padding: 10px;
  color: #777777;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  padding-bottom: 9px;
  display: inline-block;
}

/* line 48, stdin */
.mainMenu__link:hover:not(.mainMenu__link--active), .mainMenu__listItem:hover > .mainMenu__link:not(.mainMenu__link--active) {
  border-bottom: 5px solid #fbba00;
}

/* line 51, stdin */
.mainMenu__link--active {
  border-bottom: 5px solid #004f9f;
}

/* line 54, stdin */
.mainMenu__link--active + .mainMenu__dropdown {
  margin-top: 0;
}

/* line 57, stdin */
.mainMenu__dropdown {
  -webkit-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box;
  min-width: 200px;
  background-color: #fff;
  border: 1px solid #adadad;
  border-top: 0;
  margin-top: 5px;
  padding: 0;
}

/* line 66, stdin */
.mainMenu__dropdown.open {
  display: block;
  position: absolute;
  left: -60px;
  z-index: 100;
  text-align: left;
}

/* line 73, stdin */
.mainMenu__dropdown .mainMenu__link {
  width: 100%;
  border-left: 5px solid transparent;
  border-bottom: 0;
}

/* line 78, stdin */
.mainMenu__dropdown .mainMenu__link:hover, .mainMenu__dropdown .mainMenu__link--active {
  border-bottom: 0;
}

/* line 81, stdin */
.mainMenu__dropdown .mainMenu__link:hover:not(.mainMenu__link--active) {
  border-left: 5px solid #fbba00;
}

/* line 84, stdin */
.mainMenu__dropdown .mainMenu__link--active {
  border-color: #004f9f;
}

/* line 87, stdin */
.mainMenu__testMode {
  display: inline-block;
  position: relative;
  float: right;
  width: 40px;
  background-color: #e1e1e1;
  height: 20px;
  margin-left: 15px;
  top: 3px;
}

/* line 98, stdin */
.mainMenu__testModeLabel {
  float: left;
}

/* line 101, stdin */
.mainMenu__testModeToggle {
  display: block;
  position: absolute;
  left: 2px;
  top: 2px;
  height: 16px;
  width: 16px;
  background-color: #fff;
}

/* line 110, stdin */
.mainMenu__testMode--on {
  background-color: #004f9f;
}

/* line 113, stdin */
.mainMenu__testMode--on .mainMenu__testModeToggle {
  left: 22px;
}
