@charset "UTF-8";
/* line 27, stdin */
.recordList__actions {
  margin-bottom: 30px;
  text-align: right;
}

/* line 32, stdin */
.recordList__actionsButton--filter, .recordList__actionsButton--clone, .recordList__actionsButton--edit, .recordList__actionsButton--import, .recordList__actionsButton--export {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  font-family: "Interstate", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: bold;
  display: block;
  margin: 0;
  text-align: left;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  padding: 9px 40px 10px 18px;
  background-color: transparent;
  border: 2px solid #004f9f;
  color: #004f9f;
  display: inline-block;
  width: auto;
}

/* line 45, webroot/frontend-legacy/src/Mixins/Buttons.sass */
.recordList__actionsButton--filter:after, .recordList__actionsButton--clone:after, .recordList__actionsButton--edit:after, .recordList__actionsButton--import:after, .recordList__actionsButton--export:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 14px;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -5px;
}

/* line 65, webroot/frontend-legacy/src/Mixins/Buttons.sass */
.recordList__actionsButton--filter:after, .recordList__actionsButton--filter:before, .recordList__actionsButton--clone:after, .recordList__actionsButton--clone:before, .recordList__actionsButton--edit:after, .recordList__actionsButton--edit:before, .recordList__actionsButton--import:after, .recordList__actionsButton--import:before, .recordList__actionsButton--export:after, .recordList__actionsButton--export:before {
  color: #004f9f;
}

/* line 41, stdin */
.recordList__actionsButton--filter:after, .recordList__actionsButton--clone:after, .recordList__actionsButton--edit:after, .recordList__actionsButton--import:after, .recordList__actionsButton--export:after {
  content: "";
  margin-top: -7px;
}

/* line 45, stdin */
.recordList__actionsButton--filter.expanded:after, .recordList__actionsButton--clone.expanded:after, .recordList__actionsButton--edit.expanded:after, .recordList__actionsButton--import.expanded:after, .recordList__actionsButton--export.expanded:after {
  content: "";
}

/* line 48, stdin */
.recordList__actionsButton--import:after {
  content: "";
}

/* line 51, stdin */
.recordList__actionsButton--edit:after {
  content: "";
}

/* line 54, stdin */
.recordList__actionsButton--clone:after {
  content: "";
}

/* line 57, stdin */
.recordList__actionsButton--export:after {
  content: "";
}

/* line 60, stdin */
.recordList__actionsButton--delete, .recordList__actionsButton--new {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  font-family: "Interstate", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: bold;
  display: block;
  margin: 0;
  text-align: left;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  padding: 9px 40px 10px 18px;
  border: 2px solid #004f9f;
  background-color: #004f9f;
  color: #fff;
  display: inline-block;
  width: auto;
}

/* line 45, webroot/frontend-legacy/src/Mixins/Buttons.sass */
.recordList__actionsButton--delete:after, .recordList__actionsButton--new:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 14px;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -5px;
}

/* line 58, webroot/frontend-legacy/src/Mixins/Buttons.sass */
.recordList__actionsButton--delete:after, .recordList__actionsButton--delete:before, .recordList__actionsButton--new:after, .recordList__actionsButton--new:before {
  color: #fff;
}

/* line 66, stdin */
.recordList__actionsButton--delete:after, .recordList__actionsButton--new:after {
  content: "";
  margin-top: -7px;
}

/* line 70, stdin */
.recordList__actionsButton--delete {
  background-color: firebrick;
  border-color: firebrick;
}

/* line 74, stdin */
.recordList__actionsButton--delete:after {
  content: "";
}

/* line 77, stdin */
.recordList__slicer {
  max-width: 300px;
  word-break: break-all;
}

/* line 81, stdin */
.recordList__centered {
  text-align: center;
}

/* line 84, stdin */
.recordList__empty {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  color: #333333;
  margin-top: 45px;
}

/* line 88, stdin */
.recordList__empty:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 20px;
  color: firebrick;
  margin-right: 15px;
}

/* line 93, stdin */
.recordList__rowActions {
  position: relative;
  width: 40px;
}

/* line 97, stdin */
.recordList__rowActionsButton {
  cursor: pointer;
  width: 40px;
}

/* line 101, stdin */
.recordList__rowActionsButton:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 18px;
  color: #adadad;
  border: 0;
  vertical-align: inherit;
  margin: 0;
}

/* line 108, stdin */
.recordList__rowActionsMenu {
  position: absolute;
  -webkit-box-shadow: 0px 5px 8px -4px #e1e1e1;
  -moz-box-shadow: 0px 5px 8px -4px #e1e1e1;
  -o-box-shadow: 0px 5px 8px -4px #e1e1e1;
  box-shadow: 0px 5px 8px -4px #e1e1e1;
  padding: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box;
  right: 7px;
  top: 50%;
  border: 1px solid #adadad;
  background-color: #fff;
  z-index: 10;
  list-style: none;
}

/* line 120, stdin */
.recordList__rowActionsMenu:before {
  content: "";
  display: block;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #adadad;
  border-top: none;
  right: 14px;
  top: -9px;
}

/* line 130, stdin */
.recordList__rowActionsMenuChild {
  padding: 10px;
  width: 100%;
  border-left: 5px solid transparent;
  border-bottom: 0;
}

/* line 136, stdin */
.recordList__rowActionsMenuChild:hover {
  border-left: 5px solid #fbba00;
  border-bottom: 0;
}

/* line 140, stdin */
.recordList__rowActionsMenuChild--delete:hover {
  border-left-color: firebrick;
}

/* line 143, stdin */
.recordList__table {
  width: 100%;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
}

/* line 147, stdin */
.recordList__table td > a {
  font-family: "Interstate", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  color: #004f9f;
  cursor: pointer;
}

/* line 152, stdin */
.recordList__table th, .recordList__table td {
  padding: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #adadad;
}

/* line 158, stdin */
.recordList__table tr:last-child td {
  border-bottom: 0;
}

/* line 161, stdin */
.recordList__table td:not(:last-child) {
  min-width: 70px;
}

/* line 164, stdin */
.recordList__table th {
  white-space: nowrap;
  text-align: left;
  border-bottom-width: 3px;
}

/* line 170, stdin */
.recordList__table th a {
  color: #004f9f;
}

/* line 174, stdin */
.recordList__table th a.asc:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 12px;
  padding-left: 5px;
}

/* line 179, stdin */
.recordList__table th a.desc:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 12px;
  padding-left: 5px;
}

/* line 183, stdin */
.recordList__table thead > tr:first-child th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
  -webkit-box-shadow: 0px 1px 0px 0px #adadad;
  -moz-box-shadow: 0px 1px 0px 0px #adadad;
  -o-box-shadow: 0px 1px 0px 0px #adadad;
  box-shadow: 0px 1px 0px 0px #adadad;
}
