@charset "UTF-8";
/* line 9, stdin */
.error__text {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
}

/* line 12, stdin */
.error__backButton {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  font-family: "Interstate", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: bold;
  display: block;
  margin: 0;
  text-align: left;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  padding: 10px 18px 9px 50px;
  background-color: transparent;
  border: 2px solid #004f9f;
  color: #004f9f;
  width: 300px;
}

/* line 36, webroot/frontend-legacy/src/Mixins/Buttons.sass */
.error__backButton:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 14px;
  display: inline-block;
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -7px;
}

/* line 65, webroot/frontend-legacy/src/Mixins/Buttons.sass */
.error__backButton:after, .error__backButton:before {
  color: #004f9f;
}

/* line 17, stdin */
.error__stackTrace h3 {
  display: block;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 35px;
  line-height: 45px;
  color: #004f9f;
  margin-bottom: 15px;
  margin-top: 60px;
}

/* line 21, stdin */
.error__stackTrace ul {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  margin-bottom: 60px;
}

/* line 126, node_modules/@labor/bootstrap-addons/sass/mixins/generalHelpers.sass */
.error__stackTrace ul li {
  display: block;
  padding: 0;
  margin: 0;
}

/* line 26, stdin */
.error__stackTrace ul li {
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid #adadad;
  padding-left: 30px;
}

/* line 31, stdin */
.error__stackTrace ul li:last-child {
  border-bottom: 0;
}
