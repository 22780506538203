/* line 6, stdin */
.pageHeader {
  width: 100%;
  height: 50px;
  border-bottom: 1px #adadad solid;
}

/* line 13, stdin */
.pageHeader__logo {
  padding: 5px 0;
  height: 40px;
  margin-top: 5px;
}
