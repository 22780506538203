@charset "UTF-8";
/* line 6, stdin */
.blurText {
  position: relative;
}

/* line 10, stdin */
.blurText__content {
  opacity: 0;
  filter: alpha(opacity=0);
}

/* line 13, stdin */
.blurText:after {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  content: "•••••••••";
  position: absolute;
  left: 15px;
}

/* line 20, stdin */
.blurText:hover:after {
  content: "";
}

/* line 23, stdin */
.blurText:hover .blurText__content {
  opacity: 100;
  filter: alpha(opacity=10000);
}
