@charset "UTF-8";
/* line 9, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formElements__input {
  background-color: #fafafa;
  border: 1px solid #adadad;
  padding: 15px;
  color: #333333;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

/* line 19, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formElements__input:focus {
  background-color: #fff;
}

/* line 22, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formElements__input[readonly], .formElements__input[readonly]:focus {
  background-color: #e1e1e1;
}

/* line 26, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formElements__inputLabel {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  margin-top: 9px;
}

/* line 31, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formElements__input--date, .formElements__input--euro {
  padding-right: 40px;
}

/* line 35, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formElements__inputIcon {
  position: absolute;
}

/* line 39, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formElements__inputIcon--fix {
  right: 0;
  margin-right: 15px;
  margin-top: -50px;
}

/* line 44, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formElements__inputIcon:after {
  display: block;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 20px;
  margin-left: -35px;
  margin-top: 15px;
}

/* line 50, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formElements__input--date + .formElements__inputIcon:after {
  content: "";
}

/* line 53, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formElements__input--euro + .formElements__inputIcon:after {
  content: "";
}

/* line 58, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formElements__checkbox {
  opacity: 0;
  filter: alpha(opacity=0);
  width: 35px;
  height: 35px;
}

/* line 64, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formElements__checkboxWrapper {
  position: relative;
}

/* line 67, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formElements__checkbox + .formElements__checkboxOverlay:after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  background-color: #fafafa;
  content: '';
  border: 1px solid #adadad;
  padding: 15px;
  cursor: pointer;
  pointer-events: none;
}

/* line 79, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formElements__checkbox:checked + .formElements__checkboxOverlay {
  height: 0;
}

/* line 81, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formElements__checkbox:checked + .formElements__checkboxOverlay::after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 10px;
  padding: 10px;
  color: #004f9f;
}

/* line 88, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formElements__submit {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  font-family: "Interstate", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: bold;
  display: block;
  margin: 0;
  text-align: left;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  padding: 9px 40px 10px 18px;
  border: 2px solid #004f9f;
  background-color: #004f9f;
  color: #fff;
}

/* line 45, webroot/frontend-legacy/src/Mixins/Buttons.sass */
.formElements__submit:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 14px;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -5px;
}

/* line 58, webroot/frontend-legacy/src/Mixins/Buttons.sass */
.formElements__submit:after, .formElements__submit:before {
  color: #fff;
}

/* line 93, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formElements__description {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  color: #777777;
  margin-top: 4px;
}

/* line 98, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formElements__description a {
  color: #004f9f;
  font-family: "Interstate", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
}

/* line 106, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.required .formElements__inputLabel:after {
  content: "*";
  margin-left: 2px;
}

/* line 114, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formLayout__row--labelLeft .formElements__inputLabel {
  display: block;
  float: left;
  width: 20%;
  min-height: 52px;
  margin-right: 30px;
}

/* line 121, webroot/frontend-legacy/src/Components/Form/FormElements.sass */
.formLayout__row--labelLeft .formElements__input, .formLayout__row--labelLeft .formElements__checkboxWrapper, .formLayout__row--labelLeft .formElements__description {
  display: inline-block;
  width: 40%;
}

/* line 6, webroot/frontend-legacy/src/Components/Form/FormLayout.sass */
.formLayout__row {
  margin-top: 30px;
}

/* line 14, webroot/frontend-legacy/src/Components/Form/FormLayout.sass */
.formLayout fieldset {
  border: 0;
  margin-bottom: 60px;
}

/* line 18, webroot/frontend-legacy/src/Components/Form/FormLayout.sass */
.formLayout fieldset legend {
  display: block;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  color: #004f9f;
  margin-bottom: 0;
  border-bottom: 1px solid #adadad;
  padding-bottom: 10px;
  width: 100%;
}

/* line 26, webroot/frontend-legacy/src/Components/Form/FormLayout.sass */
.formLayout__actions {
  margin-top: 15px;
  text-align: right;
}

/* line 34, webroot/frontend-legacy/src/Components/Form/FormLayout.sass */
.formLayout__actions input.formLayout__actionsButton--save,
.formLayout__actions input.formLayout__actionsButton--login, .formLayout__actionsButton--save {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  font-family: "Interstate", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: bold;
  display: block;
  margin: 0;
  text-align: left;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  padding: 9px 40px 10px 18px;
  border: 2px solid #004f9f;
  background-color: #004f9f;
  color: #fff;
  display: inline-block;
  width: auto;
}

/* line 45, webroot/frontend-legacy/src/Mixins/Buttons.sass */
.formLayout__actions input.formLayout__actionsButton--save:after,
.formLayout__actions input.formLayout__actionsButton--login:after, .formLayout__actionsButton--save:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 14px;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -5px;
}

/* line 58, webroot/frontend-legacy/src/Mixins/Buttons.sass */
.formLayout__actions input.formLayout__actionsButton--save:after, .formLayout__actions input.formLayout__actionsButton--save:before,
.formLayout__actions input.formLayout__actionsButton--login:after,
.formLayout__actions input.formLayout__actionsButton--login:before, .formLayout__actionsButton--save:after, .formLayout__actionsButton--save:before {
  color: #fff;
}

/* line 41, webroot/frontend-legacy/src/Components/Form/FormLayout.sass */
.formLayout__actionsButton--cancel {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  font-family: "Interstate", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: bold;
  display: block;
  margin: 0;
  text-align: left;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  padding: 9px 40px 10px 18px;
  background-color: transparent;
  border: 2px solid #004f9f;
  color: #004f9f;
  display: inline-block;
  width: auto;
}

/* line 45, webroot/frontend-legacy/src/Mixins/Buttons.sass */
.formLayout__actionsButton--cancel:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 14px;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -5px;
}

/* line 65, webroot/frontend-legacy/src/Mixins/Buttons.sass */
.formLayout__actionsButton--cancel:after, .formLayout__actionsButton--cancel:before {
  color: #004f9f;
}

/* line 45, webroot/frontend-legacy/src/Components/Form/FormLayout.sass */
.formLayout__actionsButton--cancel:after {
  margin-top: -10px;
  right: 15px;
  font-size: 20px;
}

/* line 50, webroot/frontend-legacy/src/Components/Form/FormLayout.sass */
.formLayout__actionsButton--cancel:after {
  content: "";
}

/* line 55, webroot/frontend-legacy/src/Components/Form/FormLayout.sass */
.formLayout__actions a.formLayout__actionsButton,
.formLayout__actions input.formLayout__actionsButton {
  margin-left: 15px;
}

/* line 62, webroot/frontend-legacy/src/Components/Form/FormLayout.sass */
.formLayout__buttonIcon {
  display: inline-block;
  position: absolute;
  margin-left: -35px;
  margin-top: 14px;
}

/* line 68, webroot/frontend-legacy/src/Components/Form/FormLayout.sass */
.formLayout__buttonIcon--save:after, .formLayout__buttonIcon--login:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 20px;
  color: #fff;
}

/* line 73, webroot/frontend-legacy/src/Components/Form/FormLayout.sass */
.formLayout__buttonIcon--login:after {
  content: "";
}
