/* line 6, stdin */
.leadValue {
  display: inline-block;
}

/* line 14, stdin */
.leadValue--missmatch .leadValue__suffix, .leadValue--override .leadValue__suffix, .leadValue--campaignEmpty .leadValue__suffix {
  display: inline-block;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #777777;
  white-space: nowrap;
  color: #fff;
  background-color: #004f9f;
  padding: 10px;
}

/* line 23, stdin */
.leadValue--missmatch .leadValue__suffix {
  background-color: firebrick;
  color: #fff;
}

/* line 28, stdin */
.leadValue--campaignEmpty .leadValue__suffix {
  background-color: #ebebeb;
  color: #777777;
}
