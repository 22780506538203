/* line 9, stdin */
.recordDetail__list {
  margin-top: 0;
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
}

/* line 14, stdin */
.recordDetail__list a {
  font-family: "Interstate", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  color: #004f9f;
}

/* line 18, stdin */
.recordDetail__listRow {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  border-bottom: 1px solid #adadad;
  float: left;
  width: 100%;
}

/* line 26, stdin */
.recordDetail__listRow:hover {
  background-color: #f5f5f5;
}

/* line 29, stdin */
.recordDetail__listTitle {
  font-family: "Interstate", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  width: 25%;
  float: left;
  min-height: 10px;
}

/* line 35, stdin */
.recordDetail__listValue {
  display: inline-block;
  padding-left: 30px;
  max-width: 60%;
  word-break: break-all;
}

/* line 32, node_modules/@labor/bootstrap-addons/sass/mixins/generalHelpers.sass */
.recordDetail__listValue:before, .recordDetail__listValue:after {
  content: "";
  display: table;
}

/* line 36, node_modules/@labor/bootstrap-addons/sass/mixins/generalHelpers.sass */
.recordDetail__listValue:after {
  clear: both;
}

/* line 48, stdin */
.recordDetail__listChild--0 .recordDetail__listRow {
  position: relative;
  padding-left: 30px;
}

/* line 52, stdin */
.recordDetail__listChild--0 .recordDetail__listRow:before {
  content: "";
  position: absolute;
  display: block;
  border-left: 5px solid #004f9f;
  margin-left: -30px;
  top: 0;
  bottom: 0;
}

/* line 48, stdin */
.recordDetail__listChild--1 .recordDetail__listRow {
  position: relative;
  padding-left: 60px;
}

/* line 52, stdin */
.recordDetail__listChild--1 .recordDetail__listRow:before {
  content: "";
  position: absolute;
  display: block;
  border-left: 5px solid #004f9f;
  margin-left: -30px;
  top: 0;
  bottom: 0;
}

/* line 48, stdin */
.recordDetail__listChild--2 .recordDetail__listRow {
  position: relative;
  padding-left: 90px;
}

/* line 52, stdin */
.recordDetail__listChild--2 .recordDetail__listRow:before {
  content: "";
  position: absolute;
  display: block;
  border-left: 5px solid #004f9f;
  margin-left: -30px;
  top: 0;
  bottom: 0;
}

/* line 48, stdin */
.recordDetail__listChild--3 .recordDetail__listRow {
  position: relative;
  padding-left: 120px;
}

/* line 52, stdin */
.recordDetail__listChild--3 .recordDetail__listRow:before {
  content: "";
  position: absolute;
  display: block;
  border-left: 5px solid #004f9f;
  margin-left: -30px;
  top: 0;
  bottom: 0;
}

/* line 48, stdin */
.recordDetail__listChild--4 .recordDetail__listRow {
  position: relative;
  padding-left: 150px;
}

/* line 52, stdin */
.recordDetail__listChild--4 .recordDetail__listRow:before {
  content: "";
  position: absolute;
  display: block;
  border-left: 5px solid #004f9f;
  margin-left: -30px;
  top: 0;
  bottom: 0;
}

/* line 48, stdin */
.recordDetail__listChild--5 .recordDetail__listRow {
  position: relative;
  padding-left: 180px;
}

/* line 52, stdin */
.recordDetail__listChild--5 .recordDetail__listRow:before {
  content: "";
  position: absolute;
  display: block;
  border-left: 5px solid #004f9f;
  margin-left: -30px;
  top: 0;
  bottom: 0;
}

/* line 61, stdin */
.recordDetail__selectable {
  font-family: "Interstate Light", "Helvetica Neue", "Verdana", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  padding: 30px;
  background-color: #f5f5f5;
  margin-top: 15px;
}
